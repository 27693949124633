.default-border {
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12) !important;
}

.action-text {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: grey;
  }
}