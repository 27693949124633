@import '../../index.scss' ;

.primary-text-color {
  color: $lightTextColor;

  td {
    color: $lightTextColor !important;
  }

  th {
    color: $lightTextColor !important;
  }
}

.transparent-background {
  background-color: transparent !important;
}

.white-text-color {
  span {
    color: $text3color !important;
  }
}