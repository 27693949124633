/**
Font
*/

$fontFamily: 'Roboto', sans-serif;
$fontSizeRegular: 14px;

$fontWeightRegular: 400;

/**
Colors
*/

// dark theme
// $lightTextColor: rgb(246, 247, 249);
// $lightBorderColor: rgb(246, 247, 249, 0.25);
// $darkTextColor: #8b92a4;
// $primaryTextColor: #08b2eb; // same as highlight color

// $dark1Color: #16181d;
// $dark2color: #23272f;
// $dark3color: #353a46;
// $highlightColor: #08b2eb;

// blue theme
// $lightTextColor: rgba(0, 0, 0, 0.6);
$lightTextColor: #0b3558;
$lightBorderColor: rgba(0, 0, 0, 0.3);
$text3color: white;
// $primaryTextColor: #1976d2; // same as highlight color
$primaryTextColor: #0f6bff; // same as highlight color

// $dark1Color: rgb(243, 246, 247);
$dark1Color: #f4f8ff;
$dark3color: white;
$highlightColor: #0f6bff;
$alternateHighlightColor: white;

// COLOR NOTES
//
// primary color  = #0f6bff
// primary color (hover) = #0a4ab2
//
// secondary color = #f4f8ff
// secondary color (hover) = rgba(15, 107, 255, 0.04)
//
// text color = #0b3558
//
// border color = rgba(0, 0, 0, 0.3)

// dark blue theme
// $lightTextColor: rgba(0, 0, 0, 0.6);
// $lightBorderColor: rgba(0, 0, 0, 0.3);
// $text3color: white;
// $primaryTextColor: #5bbac1; // same as highlight color

// $dark1Color: rgba(14,42,53,255);
// $dark2color: #23272f;
// $dark3color: white;
// $highlightColor: #5bbac1;

/**
Font
*/

// .light-text-color {
//   color: $lightTextColor;
// }

// .transparent-light-text-color {
//   color: $lightBorderColor;
// }

// .primary-text-color {
//   color: $primaryTextColor;
// }

// .alternate-highlight-color {
//   color: $alternateHighlightColor;
// }

// .text3-color {
//   color: $text3color;
// }

.font-weight-regular {
  font-weight: $fontWeightRegular !important;
}

.font-size-regular {
  font-size: $fontSizeRegular !important;
}

/**
Background
*/

// .dark1-background-color {
//   background-color: $dark1Color !important;
// }

// .dark3-background-color {
//   background-color: $dark3color !important;
// }

// .highlight-background-color {
//   background-color: $highlightColor !important;
// }

// .light-background-color {
//   background-color: $lightTextColor !important;
// }

/**
Border
*/

// .light-border-color {
//   border-color: $lightTextColor !important;
// }

// .primary-border-color {
//   border-color: $primaryTextColor !important;
// }

// .light-rounded-border {
//   border: solid 1px $lightTextColor;
//   border-radius: 4px;
// }

// .light-thin-rounded-border {
//   border: solid 1px $lightBorderColor;
//   border-radius: 4px;
// }

// .light-thin-border {
//   border: '1px solid rgba(255, 255, 255, 0.2)' !important;
// }

/**
Color
*/

// .primary-color {
//   color: $primaryTextColor !important;
// }

/**
Body
*/
body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// Mixins for responsive breakpoints
@mixin phone {
  @media (max-width: 575px) { @content; }
}

@mixin tablet {
  @media (min-width: 576px) and (max-width: 767px) { @content; }
}

@mixin desktop-small {
  @media (min-width: 768px) and (max-width: 991px) { @content; }
}

@mixin desktop {
  @media (min-width: 992px) and (max-width: 1199px) { @content; }
}

@mixin desktop-large {
  @media (min-width: 1200px) { @content; }
}

// Base font size for mobile-first approach
html {
  font-size: 14px; // Adjust base font size as needed
  
  @include tablet {
    font-size: 11px; // Slightly larger font size for tablets
  }
  
  @include desktop-small {
    font-size: 12px; // Standard font size for small desktops
  }
  
  @include desktop {
    font-size: 13px; // Increased font size for desktops
  }
  
  @include desktop-large {
    font-size: 15px; // Largest font size for large desktops
  }
}

/** New Design, do not use above colors, classes, or mixins */

/**
New Design System Colors
*/
$dark-bg-primary: #0a0c1b;
$dark-bg-secondary: #1a1c2a;
$dark-bg-tertiary: #23252f;
$accent-primary: #5da8ee;
$accent-secondary: #3a7ced; // #2196f3 -> MUI blue
$text-primary: #ffffff;
$text-secondary: #94a3b8;
$glass-bg: rgba(255, 255, 255, 0.03);
$glass-border: rgba(255, 255, 255, 0.1);
$border-light: rgba(255, 255, 255, 0.1);
$input-readonly-bg: rgba(0, 0, 0, 0.05);

// New color variables
$input-border: rgba(255, 255, 255, 0.2);
$input-border-hover: rgba(255, 255, 255, 0.3);
$shadow-color: rgba(79, 70, 229, 0.4);
$shadow-color-hover: rgba(79, 70, 229, 0.6);
$button-hover-bg: rgba(79, 70, 229, 0.1);
$text-shadow-color: rgba(0, 0, 0, 0.1);
$nav-active-bg: rgba(255, 255, 255, 0.1);

/**
Gradients
*/
$gradient-hero-bg: linear-gradient(135deg, $dark-bg-primary 0%, $dark-bg-secondary 100%);
$gradient-accent: linear-gradient(90deg, $accent-primary 0%, $accent-secondary 100%);
$gradient-glow: radial-gradient(circle at 50% 50%, rgba(99, 102, 241, 0.4) 0%, transparent 50%);

/**
Animations
*/
@keyframes pulse {
  0% { transform: scale(1); opacity: 0.1; }
  50% { transform: scale(1.1); opacity: 0.2; }
  100% { transform: scale(1); opacity: 0.1; }
}

/**
Utility Classes
*/
.bg-dark-primary {
  background-color: $dark-bg-primary !important;
}

.bg-dark-secondary {
  background-color: $dark-bg-secondary !important;
}

.bg-dark-tertiary {
  background-color: $dark-bg-tertiary !important;
}

.bg-gradient-hero {
  background: $gradient-hero-bg;
}

.bg-gradient-accent {
  background: $gradient-accent;
}

.text-primary {
  color: $text-primary !important;
}

.text-secondary {
  color: $text-secondary !important;
}

/**
Component Classes
*/
.glass-panel {
  background: $glass-bg;
  backdrop-filter: blur(20px);
  border: 1px solid $glass-border;
  border-radius: 24px;
}

.accent-badge {
  background: $gradient-accent;
  padding: 8px 16px;
  border-radius: 20px;
  display: inline-block;
  width: fit-content;
}

.animated-glow {
  background: $gradient-glow;
  animation: pulse 8s ease-in-out infinite;
}

.hero-button {
  padding: 16px 40px;
  font-size: 1.125rem;
  font-weight: 600;
  border-radius: 8px;
  transition: all 0.3s ease;

  &.primary {
    background: $gradient-accent;
    box-shadow: 0 4px 14px $shadow-color;
    color: $text-primary !important;
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 6px 20px $shadow-color-hover;
    }
  }

  &.secondary {
    border: 1px solid $accent-primary;
    color: $text-primary !important;
    
    &:hover {
      background: $button-hover-bg;
      transform: translateY(-2px);
    }
  }
}

.hero-title {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: $text-primary;
  text-shadow: 0 2px 4px $text-shadow-color;
}

.hero-subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  color: $text-secondary;
}

/**
Layout Classes
*/
.section-padding {
  padding: 120px 32px;
}

.section-padding-small {
  padding: 60px 32px;
}

.content-max-width {
  max-width: 1200px;
  margin: 0 auto;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/**
Responsive Utilities
*/
.responsive-margin-top {
  margin-top: 48px;
  @media (min-width: 900px) {
    margin-top: 0;
  }
}

.border-top-light {
  border-top: 1px solid $border-light;
}

.border-light {
  border: 2px solid $glass-border;
  border-radius: 4px;
}

.link-hover {
  transition: color 0.3s ease;
  &:hover {
    color: $text-primary;
  }
}

// Add to Design System section
$nav-active-bg: rgba(255, 255, 255, 0.1);

// Add to Border Classes section
.border-bottom-light {
  border-bottom: 1px solid $border-light;
}

// Add to Component Classes section
.nav-button {
  color: $text-secondary !important;
  padding: 8px 8px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  transition: all 0.3s ease;
  
  &:hover {
    color: $text-primary;
    background: $nav-active-bg;
  }
  
  &.active {
    color: $text-primary;
    background: $nav-active-bg;
  }
}

.app-bar-override {
  background-color: $dark-bg-secondary !important;
  box-shadow: none !important;
}

/**
Form Input Styles
*/
.mui-dark-input {
  // Base styles for the input
  .MuiOutlinedInput-root {
    color: $text-primary;
    margin: 8px; // equivalent to m: 1 in MUI
    
    // Border styles
    & fieldset {
      border-color: $input-border;
    }
    
    // Hover state
    &:hover fieldset {
      border-color: $input-border-hover;
    }
    
    // Focus state
    &.Mui-focused fieldset {
      border-color: $accent-secondary;
    }

    .MuiInputBase-input {
      color: $text-secondary;
    }

    // Read-only input styles
    .MuiInputBase-input[readonly] {
      background-color: $input-readonly-bg;
      cursor: default;
    }

    // Input adornment styles
    .MuiInputAdornment-root {
      color: $text-secondary;
      
      p {
        color: $text-secondary;
      }
    }
  }
  
  // Label styles
  .MuiInputLabel-root {
    color: $text-secondary;
    margin: 8px; // equivalent to m: 1 in MUI
    
    &.Mui-focused {
      color: $accent-secondary;
    }
  }
}

/**
Form Input Styles - Disabled
*/
.mui-dark-input-disabled {
  // Base styles for the input
  .MuiOutlinedInput-root {
    color: rgba($text-secondary, 0.6); // Darker text color
    margin: 8px;
    
    // Border styles
    & fieldset {
      border-color: rgba($border-light, 0.3); // Darker border
    }
    
    // Hover state - disabled
    &:hover fieldset {
      border-color: rgba($border-light, 0.3);
    }
    
    // Focus state - disabled
    &.Mui-focused fieldset {
      border-color: rgba($border-light, 0.3);
    }

    .MuiInputBase-input {
      color: rgba($text-secondary, 0.6);
      -webkit-text-fill-color: rgba($text-secondary, 0.6);
      cursor: not-allowed;
    }

    // Input adornment styles
    .MuiInputAdornment-root {
      color: rgba($text-secondary, 0.6);
      
      p {
        color: rgba($text-secondary, 0.6);
      }
    }
  }
  
  // Label styles
  .MuiInputLabel-root {
    color: rgba($text-secondary, 0.6);
    margin: 8px;
    
    &.Mui-focused {
      color: rgba($text-secondary, 0.6);
    }
  }
}

/**
MUI Tabs Dark Theme
*/
.mui-dark-tabs {
  // Tab list styles
  .MuiTabs-root {
    background: $dark-bg-secondary;
    border-radius: 8px;
    min-height: 48px;
  }

  // Individual tab styles
  .MuiTab-root {
    color: $text-secondary;
    text-transform: none;
    font-weight: 500;
    min-height: 48px;
    padding: 12px 24px;
    
    &:hover {
      color: $text-primary;
      background: $nav-active-bg;
    }
    
    &.Mui-selected {
      color: $text-primary;
    }
  }

  // Indicator styles
  .MuiTabs-indicator {
    background: $gradient-accent;
    height: 3px;
    border-radius: 1.5px;
  }

  // Scrollbar styles
  .MuiTabs-scrollButtons {
    color: $text-secondary;
    
    &.Mui-disabled {
      opacity: 0.3;
    }
  }
}

/**
Utility Classes for Text Colors
*/
.text-input-primary {
  color: $text-primary !important;
}

.text-input-secondary {
  color: $text-secondary !important;
}

.text-tab-primary {
  color: $text-primary !important;
}

.text-tab-secondary {
  color: $text-secondary !important;
}

/**
Icon Styles
*/
.icon-accent {
  color: $text-primary !important;
  background-color: $accent-primary !important;
  
  &.gradient {
    background: $gradient-accent;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent !important;
  }
}

.icon-negative-accent {
  color: $accent-primary !important;
}

.icon-primary {
  color: $text-primary !important;
}

.icon-secondary {
  color: $text-secondary !important;
}

/**
Chart Styles
*/
.chart-dark-theme {
  .recharts-cartesian-axis-line {
    stroke: $text-primary !important;
  }
  
  .recharts-cartesian-axis-tick-line {
    stroke: $text-primary !important;
  }
  
  .recharts-cartesian-axis-tick-value {
    fill: $text-primary !important;
  }
  
  .recharts-label {
    fill: $text-primary !important;
  }
  
  .recharts-text {
    fill: $text-primary !important;
  }
  
  .recharts-legend-item-text {
    color: $text-primary !important;
  }
  
  .recharts-cartesian-grid-horizontal,
  .recharts-cartesian-grid-vertical {
    stroke: rgba(255, 255, 255, 0.1) !important;
  }
}

/**
Table Dark Theme
*/
.table-dark-theme {
  // Header styles
  .MuiTableHead-root {
    .MuiTableCell-root {
      background-color: $dark-bg-tertiary !important;
      color: $text-primary !important;
      border-bottom-color: $border-light !important;
      font-weight: 600;
    }
  }

  // Body styles
  .MuiTableBody-root {
    .MuiTableCell-root {
      background-color: transparent !important;
      color: $text-primary !important;
      border-bottom-color: $border-light !important;
    }

    .MuiTableRow-root {
      &:hover {
        background-color: $nav-active-bg !important;
      }
    }
  }

  // Remove default background from sticky header
  .MuiTableCell-stickyHeader {
    background-color: $dark-bg-primary !important;
  }
}

/**
Select Dark Theme
*/
.select-dark-theme {
  .MuiInputLabel-root {
    color: $text-secondary !important;
    
    &.Mui-focused {
      color: $accent-primary !important;
    }
  }

  .MuiSelect-select {
    color: $text-primary !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $border-light !important;
  }

  .MuiSvgIcon-root {
    color: $text-primary !important;
  }

  .MuiMenuItem-root {
    color: $text-primary !important;
    
    &:hover {
      background-color: $nav-active-bg !important;
    }
    
    &.Mui-selected {
      background-color: rgba($accent-primary, 0.1) !important;
      
      &:hover {
        background-color: rgba($accent-primary, 0.2) !important;
      }
    }
  }
}

/**
Checkbox Dark Theme - Disabled
*/
.compare-checkbox-non-active {
  .MuiCheckbox-root {
    color: rgba($text-secondary, 0.6) !important;
    
    // Style for the unchecked state
    .MuiSvgIcon-root {
      color: rgba($text-secondary, 0.6) !important;
    }
  }
  
  .MuiFormControlLabel-label {
    color: rgba($text-secondary, 0.6) !important;
  }
}

.compare-checkbox-active {
  .MuiCheckbox-root {
    color: $accent-primary !important;
    
    .MuiSvgIcon-root {
      color: $accent-primary !important;
    }
  }
  
  .MuiFormControlLabel-label {
    color: $text-primary !important;
  }
}

/**
Info Icon - Disabled
*/
.info-icon-disabled {
  color: rgba($text-secondary, 0.6) !important;
  cursor: not-allowed;
}

.info-icon-enabled {
  color: $accent-primary !important;
}

/**
Switch Dark Theme
*/
.switch-dark-theme {
  .MuiSwitch-root {
    .MuiSwitch-switchBase {
      color: $text-secondary !important;
      
      &.Mui-checked {
        color: $accent-primary !important;
        
        & + .MuiSwitch-track {
          background-color: rgba($accent-primary, 0.5) !important;
        }
      }
    }
    
    .MuiSwitch-track {
      background-color: rgba($text-secondary, 0.3) !important;
    }
  }

  // Label styles
  .MuiTypography-root {
    color: $text-primary !important;
  }
}



