@import '../../index.scss';

.primary-text-color {
  color: $lightTextColor;

  label {
    color: $lightTextColor !important;
  }

  div {
    color: $lightTextColor !important;
  }

  svg {
    fill: $lightTextColor !important;
  }
}
