@import '../../../index.scss';

form {
  display: flex;
  flex-direction: column;
}

.white-inputs {
  label {
    color: $lightTextColor;
  }

  input {
    color: $lightTextColor;
    border-color: $lightTextColor;
  }

  p {
    color: $lightTextColor;
  }
}