@import '../../index.scss';

.primary-color-icon {
  svg {
    fill: $lightTextColor;
    color: $lightTextColor;
  }
}

.brand-name-text {
  display: flex;
  flex-direction: row;

  p {
    margin: 0;
    font-weight: bold;
  }
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .show-on-mobile {
    display: none !important;
  }
}