@import '../../index.scss';

/* CSS */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

// .label {
//   label {
//     font-size: 18px;
//   }

//   fieldset {
//     border-color: $lightBorderColor;
//   }

//   fieldset hover {
//     border-color: $lightBorderColor;
//   }
// }

// .MuiOutlinedInput-notchedOutline {
//   border-color: $lightBorderColor !important;
// }
