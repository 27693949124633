@import '../../../index.scss';

.compare-checkbox-non-active {
  color: $lightBorderColor;

  svg {
    color: $lightBorderColor;
  }
}

.compare-checkbox-active {
  color: $lightTextColor;
}

.white-inputs {
  label {
    color: $lightTextColor;
  }

  input {
    color: $lightTextColor;
    border-color: $lightTextColor;
  }

  p {
    color: $lightTextColor;
  }
}

.light-inputs {
  label {
    color: $lightBorderColor !important;
  }

  input:disabled {
    -webkit-text-fill-color: $lightBorderColor !important;
  }

  p {
    color: $lightBorderColor;
  }
}
